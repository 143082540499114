export const MAX_ITENS_LISTADOS_LIMIT = 200;

export default {
  setarValorParametro(params, nomeParemetro, valor) {
      if (valor) {        
        params.append(nomeParemetro, valor);
      }
  },
  emailInvalido: function (email) {    
    if (email == null || email.length == 0) {
      return false;
    }
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(email);
  },
  formatar2Digitos: function (numero) {
        return Number(numero).toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  }, 
  isPanico: function (tipoAviso){
    if (tipoAviso === "AcionamentoEmergencia") { return true; } 
    else if (tipoAviso === "AcionamentoEmergenciaPassageiro") { return  true;} 
    else if (tipoAviso === "AcionamentoEmergenciaPortaMalas") { return  true;} 
    else if (tipoAviso === "AcionamentoEmergenciaBancoTraseiro") { return  true;} 
    else {
      return false
    }
  },  
  isCNPJValido:function (cnpj) {
 
      cnpj = cnpj.replace(/[^\d]+/g,'');
  
      if(cnpj == '') return false;
      
      if (cnpj.length != 14)
          return false;
  
      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" || 
          cnpj == "11111111111111" || 
          cnpj == "22222222222222" || 
          cnpj == "33333333333333" || 
          cnpj == "44444444444444" || 
          cnpj == "55555555555555" || 
          cnpj == "66666666666666" || 
          cnpj == "77777777777777" || 
          cnpj == "88888888888888" || 
          cnpj == "99999999999999")
          return false;
          
      // Valida DVs
      var tamanho = cnpj.length - 2
      var numeros = cnpj.substring(0,tamanho);
      var digitos = cnpj.substring(tamanho);
      var soma = 0;
      var pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0))
          return false;
          
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1))
            return false;
            
      return true;
      
  },
  isCPFValido: function (c) {
      if((c = c.replace(/[^\d]/g,"")).length != 11)
        return false

      if (c == "00000000000" || c == "11111111111" || c == "22222222222" || c == "33333333333" || c == "44444444444" ||
          c == "55555555555" || c == "66666666666" || c == "77777777777" || c == "88888888888" || c == "99999999999")
         return false;

      var r;
      var s = 0;
      var i = 1;

      for (i=1; i<=9; i++)
      s = s + parseInt(c[i-1]) * (11 - i);

      r = (s * 10) % 11;

      if ((r == 10) || (r == 11))
      r = 0;

      if (r != parseInt(c[9]))
      return false;

      s = 0;

      for (i = 1; i <= 10; i++)
      s = s + parseInt(c[i-1]) * (12 - i);

      r = (s * 10) % 11;

      if ((r == 10) || (r == 11))
      r = 0;

      if (r != parseInt(c[10]))
      return false;

      return true;
  },
  segundosParaHMS: function(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? (h < 10 ? "0"+h +":" : h + ":") : "00:";
    var mDisplay = m > 0 ? (m < 10 ? "0"+m +":" : m + ":") : "00:";
    var sDisplay = s > 0 ? (s < 10 ? "0"+s : s) : "00";
    return hDisplay + mDisplay + sDisplay; 
  },
  isDarkColor: function(color) {
    let r;
    let g;
    let b;
    if (color.match(/^rgb/)) {  
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);  
      r = color[1];
      g = color[2];
      b = color[3];
    } 
    else {        
      color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'
      )
      );
      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }
  
    let hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
  
    if (hsp>127.5) {
      return false;
    } 
    else {  
      return true;
    }
  },
  zeroPad(num, numZeros) {
    var n = Math.abs(num);
    var zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  },
  formatarMoedaReal(valor) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor)
  },
  formatarNumero(valor) {
    return new Intl.NumberFormat('pt-BR', { style: 'decimal',  minimumFractionDigits: 2, maximumFractionDigits: 2}).format(valor)
  },
  formatarNumero3Decimais(valor) {
    return new Intl.NumberFormat('pt-BR', { style: 'decimal',  minimumFractionDigits: 3, maximumFractionDigits: 3}).format(valor)
  },
  formatarDataYYYYMMDD(valorData) {
    let dataValor = new Date(valorData); 
    return  this.zeroPad(dataValor.getDate(),2) + "/" + this.zeroPad((dataValor.getMonth() + 1),2) + "/" + dataValor.getFullYear();
  },
  formatarTimestampParaYMD(valorEmMilisegundos) {
    let dataFromTS = new Date(valorEmMilisegundos);
    return dataFromTS.getUTCFullYear() + "-" + this.zeroPad((dataFromTS.getUTCMonth() + 1),2) + "-" + this.zeroPad(dataFromTS.getUTCDate(),2);
  },
  formatarTimestampParaYMDBarra(valorEmMilisegundos) {
    let dataFromTS = new Date(valorEmMilisegundos);
    return dataFromTS.getUTCFullYear() + "/" + this.zeroPad((dataFromTS.getUTCMonth() + 1),2) + "/" + this.zeroPad(dataFromTS.getUTCDate(),2);
  },
  formatarTimestampParaDMYHM(valorEmMilisegundos) {
    let dataFromTS = new Date(valorEmMilisegundos);
    return  this.zeroPad(dataFromTS.getUTCDate(),2) + "-" + this.zeroPad((dataFromTS.getUTCMonth() + 1),2) + "-" + dataFromTS.getUTCFullYear() + " " 
    + this.zeroPad(dataFromTS.getHours(),2) + ":" + this.zeroPad(dataFromTS.getMinutes(),2);
  },
  formatarTimestampParaDMYHMS(valorEmMilisegundos) {
    let dataFromTS = new Date(valorEmMilisegundos);
    return  this.zeroPad(dataFromTS.getUTCDate(),2) + "-" + this.zeroPad((dataFromTS.getUTCMonth() + 1),2) + "-" + dataFromTS.getUTCFullYear() + " " 
    + this.zeroPad(dataFromTS.getHours(),2) + ":" + this.zeroPad(dataFromTS.getMinutes(),2)+ ":" + this.zeroPad(dataFromTS.getSeconds(),2);
  },
  isFormatoHHMM(valorHHHMMM) {    
    var isValid = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(valorHHHMMM);
    return isValid;
  },
  getHoraMinutoInMilisegundos(valorHHHMMM) {
    if (valorHHHMMM) {      
      var timeParts = valorHHHMMM.split(":");
      return ((+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000));
    }
    return 0;
  },
  criarNumeroCarregamento() {
      var d = new Date();
      var dateString = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
      return dateString;
  },
  isCEPValido(strCEP) {
      // Caso o CEP não esteja nesse formato ele é inválido!
      var objER = /^[0-9]{2}[0-9]{3}-[0-9]{3}$/;

      if(strCEP.length > 0) {
        return (objER.test(strCEP))                  
      } 

      return false;
  }
}