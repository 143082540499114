<template>
    <div>
        <vx-card class="mr-4 p-0">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="flex items-center">
                        <vs-button icon="keyboard_backspace" color="primary" @click="voltar" size="small" class="mr-2" v-if="routeNameRetorno"> Voltar</vs-button>
                        <span class="font-bold ml-2 mr-2" v-if="veiculo.placa">{{veiculo.placa}} </span> 
                                                
                        <div class="flex items-center text-center ml-8">
                            <small ><span class="ml-4" v-if="!veiculo.hasOwnProperty('codigoModuloBkp')"> Módulo: <span class="font-bold mr-2">{{veiculo.marcaModulo}} {{veiculo.codigoModulo}}</span> </span></small>
                            <vs-radio v-model="pesquisarModuloBackup" color="success" :vs-value="false" v-if="veiculo.codigoModuloBkp" ><small >Módulo Principal <br/>{{veiculo.marcaModulo}} <span class="font-bold">{{veiculo.codigoModulo}}</span></small></vs-radio>
                            <vs-radio v-model="pesquisarModuloBackup" color="warning" :vs-value="true" v-if="veiculo.codigoModuloBkp" class="ml-4"><small>Módulo Backup <br/>{{veiculo.marcaModuloBkp}} <span class="font-bold">{{veiculo.codigoModuloBkp}}</span></small></vs-radio>
                        </div>                                          
                        <div class="flex items-center text-center ml-8">
                            <div class="vx-row mt-1">                                     
                                <div class="vx-col w-1/2">
                                    <label class="vs-input--label mr-2">Data</label>
                                    <flat-pickr v-model="filtro.dataGPSDDMMYYYY" :config="configDatePicker"/>
                                </div>
                                <div class="vx-col w-1/2 flex mt-6">
                                    <vx-tooltip text="Atualizar">                
                                        <vs-button color="primary" type="filled" :disabled="!habilitarPesquisa" icon-pack="feather" icon="icon-search" @click="fetchLista(filtro)"></vs-button>
                                    </vx-tooltip>
                                </div>           
                            </div>           
                        </div>                                           
                    </div>
                </div>                
            </div> 
             
        </vx-card>

        <vs-row v-if="exibirMensagemNenhumDadoEncontrado">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">           
                <vs-alert class="mt-4 mr-4" color="danger" title="Atenção">
                    Nenhum rastreamento encontrado.
                </vs-alert>                          
            </vs-col>
        </vs-row>
        <div class="flex items-center mt-2">                    
            <p class="text-sm" v-if="atualizadoAs">Pesquisado em <span class="font-medium">{{atualizadoAs}} </span> | </p>
            <h6 class="ml-2" v-if="listaRastreamentos.length > 0 "> {{listaRastreamentos.length}} registros</h6>     
            <vx-tooltip text="Exportar para CSV">                
                <vs-button size="small" v-if="listaRastreamentos.length > 0 " class="ml-4" color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV"></vs-button>
            </vx-tooltip>               
        </div>
        

        <ag-grid-vue
        v-if="!exibirMensagemNenhumDadoEncontrado"
        ref="agGridTable"           
        :gridOptions="configuracaoGrid.gridOptions"
        class="ag-theme-material ag-grid-table ag-grid-altura mt-1"
        :columnDefs="configuracaoGrid.columnDefs"
        :defaultColDef="configuracaoGrid.defaultColDef"
        :enableCellTextSelection="true"
        :rowData="listaRastreamentos"
        :rowHeight="20"
        :headerHeight="25"        
        rowSelection="single"
        @grid-ready="onGridReady" 
        colResizeDefault="shift"      
        :animateRows="true"
        :floatingFilter="false"
        :pagination="false"
        :paginationAutoPageSize="false"
        :suppressPaginationPanel="true"
        >
        </ag-grid-vue>   
 </div> 
</template>

<script>
import axios from "@/axios.js"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import generico from "@/generico.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'


export default {
    components: {
        flatPickr,
        AgGridVue
    },
    props: {
        veiculo: {
            type: Object,
            required: true
        },
        routeNameRetorno: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            pesquisarModuloBackup: false,
            dadosVeiculo: null,            
            listaClientes: [],
            filtro : { dataGPSDDMMYYYY: null},
            atualizadoAs: null,
            exibirMensagemNenhumDadoEncontrado: false,            
            listaRastreamentos: [],            
            configDatePicker: {          
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "d/m/Y", 
                altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
                locale: PortugueseLocale
            },     
            configuracaoGrid: { 
                gridApi: null, gridOptions: {}, gridColumnApi: null,
                defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },                          
                columnDefs: [    
                    {headerName: "N.", valueGetter: "node.rowIndex + 1", width: 40},
                    { headerName: 'Módulo', field: 'codigoModulo', width: 130 },           
                    { headerName: 'Data/Hora GPS', field: 'dataHoraGPS', width: 100 },           
                    { headerName: 'Data/Hora Recebimento', field: 'dataRecebimento', width: 140 },  
                    { headerName: 'Latitude', field: 'latitude', width: 100 },  
                    { headerName: 'Longitude', field: 'longitude', width: 100 },                                
                    { headerName: 'Panico', field: 'panico', width: 70},                                              
                    { headerName: 'Entrada 1', field: 'entrada1', width: 80},
                    { headerName: 'Entrada 2', field: 'avisoAux1', width: 80,
                        valueGetter: function(params) { 
                            return ( params.data.avisoAux1 ? '1' : '0');
                        }
                    },
                    { headerName: 'Entrada 3', field: 'entrada3', width: 80},                                              
                    { headerName: 'Entrada 4', field: 'entrada4', width: 80},                                              
                    { headerName: 'Saída 1', field: 'bloqueio', width: 60},                                              
                    { headerName: 'Saída 2', field: 'sirene', width: 60,
                        valueGetter: function(params) { 
                            return ( params.data.sirene ? '1' : '0');
                        },
                    },
                    { headerName: 'Saída 3', field: 'saida3', width: 60},                                                                  
                    { headerName: 'Ignição', field: 'ignicao', width: 100},                                              
                    { headerName: 'Vel.', field: 'velocidade', width: 60},                                              
                    { headerName: 'Sat.', field: 'satelite', width: 60},                                              
                    { headerName: 'Status', field: 'statusRecebimento', width: 100},                                                 
                    { headerName: 'Odometro m', field: 'odometro', width: 100},                                              
                    { headerName: 'Horimetro min', field: 'horimetro', width: 120},                                              
                    { headerName: 'RPM', field: 'rpm', width: 60},                                              
                    { headerName: 'Temperatura', field: 'temperatura', width: 100},
                    { headerName: 'Codigo Motorista', field: 'codigoMotorista', width: 100},                                              
                    { headerName: 'Orientacao', field: 'orientacao', width: 100},
                    { headerName: 'Voltagem', field: 'voltagemBateria', width: 100},                                                                                              
                    { headerName: 'Operadora', field: 'operadoraConectada', width: 100 },
                    { headerName: 'Nivel Bateria Bkp', field: 'nivelBateriaBackup', width: 100},                                                                                              
                    { headerName: 'Endereco', field: 'endereco', width: 300},                                                                                              
                ]
            }, 
            linhaSelecionada: null,      
            publicPath: process.env.BASE_URL
        }
    },
    computed: {
        habilitarPesquisa() {
            if (this.filtro.dataGPSDDMMYYYY) {                
                return true;                 
            }

            return false;
        }
    },
    methods: {  
        ajustarTamanhoColunas(){
            const allColumnIds = [];
            this.configuracaoGrid.gridColumnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });

            this.configuracaoGrid.gridColumnApi.autoSizeColumns(allColumnIds, false);
        },
        onGridReady(params) {
            this.configuracaoGrid.gridApi = params.api;
            this.configuracaoGrid.gridColumnApi = params.columnApi;                                       
        },          
        exportarParaCSV() {
            let codigoModulo = null;

            if (this.pesquisarModuloBackup) {
                codigoModulo = this.veiculo.codigoModuloBkp;
            } else {
                codigoModulo = this.veiculo.codigoModulo;
            }

            if (this.listaRastreamentos && this.listaRastreamentos.length > 0) {
                codigoModulo = this.listaRastreamentos[0].codigoModulo;
            }
            var params = {columnSeparator: ';', fileName: 'ListaRastreamentos - ' + codigoModulo + '.csv'};
            this.configuracaoGrid.gridApi.exportDataAsCsv(params);
        },      
        formatarNumero3Decimais(valor) {
            return generico.formatarNumero3Decimais(valor);
        },
        formatarNumero2Decimais(valor) {
            return generico.formatarNumero(valor);
        },        
        fetchLista(filtro) {     
                        
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('dataGPSDDMMYYYY', filtro.dataGPSDDMMYYYY);   
            if (this.pesquisarModuloBackup) {                
                params.append('codigoModulo', this.veiculo.codigoModuloBkp);
            } else {                
                params.append('codigoModulo', this.veiculo.codigoModulo);
            }         
                        
            axios.post("/ListarRastreamento", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.atualizadoAs = generico.formatarTimestampParaDMYHMS(new Date().getTime());
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaRastreamentos = response.data;  
                    if (this.listaRastreamentos != null && this.listaRastreamentos.length > 0) {
                        this.exibirMensagemNenhumDadoEncontrado = false;
                    } else {
                        this.exibirMensagemNenhumDadoEncontrado = true;
                    }                       
                }
                this.ajustarTamanhoColunas();  
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },        
    },
    beforeMount() {
        this.configuracaoGrid.gridOptions = {};      
        this.configuracaoGrid.defaultColDef = { sortable: true, resizable: true, suppressMenu: true  };              
    },
    mounted() {               
       this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;       
       this.configuracaoGrid.gridColumnApi = this.configuracaoGrid.gridOptions.columnApi;              
    },
}
</script>