<template>
  <div id="formulario-edit-info">
    <vs-popup title="Log de Erros" fullscreen :active.sync="popUpHistoricoEventos.exibir" v-if="popUpHistoricoEventos.exibir">
        <div class="flex justify-center mb-2">
            <h4> {{data_local.marca}}  - {{data_local.codigo}}</h4>
        </div>
       <div class="flex">
          <flat-pickr v-model="popUpHistoricoEventos.dataHora" :config="configDatePicker" placeholder="Data"/>
          <vs-button class="ml-2" icon="close" color="primary" @click="popUpHistoricoEventos.dataHora = null"/>
          <vs-button class="ml-2" icon="search" color="primary" @click="fetchHistoricoErros()"/>          
       </div>
       <vs-table :data="popUpHistoricoEventos.dadosDaGridHistorico" class="tabelaComScroll mt-2" noDataText="Nenhum evento encontrado" max-items="10">
          <template slot="thead">
              <vs-th>Data</vs-th>
              <vs-th>Tipo</vs-th>                          
              <vs-th>Descrição</vs-th>            
          </template>
          <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td><small>{{tr.evento}}</small></vs-td>                
                <vs-td><small>{{tr.dataHora}}</small> </vs-td>
                <vs-td><small>{{tr.descricaoComplementar}}</small> </vs-td>
              </vs-tr>
          </template>
       </vs-table>
    </vs-popup>    
     <vs-popup fullscreen title="Listar Rastreamentos" :active.sync="popUpListarRastreamentos.exibir" v-if="popUpListarRastreamentos.exibir">
        <ListarRastreamentos :veiculo="popUpListarRastreamentos.veiculo" />
    </vs-popup>   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div class="vx-row">
            <div class="vx-col w-full">                    
                <vs-button class="ml-auto mt-2" size="small" @click="gravar" v-show="isAllowedToSave" color="success">Gravar</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="isDeletable" color="danger">Excluir</vs-button>            
                <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
                <vs-button class="ml-4 mt-2" size="small" type="border" color="warning" @click="abrirPopUpHistoricoErros">Log de Erros</vs-button> 
                <vs-button class="ml-4 mt-2" size="small" type="border" color="warning" @click="abrirPopUpListarRastreamentos">Listar Rastreamento</vs-button> 
            </div>
        </div>

        <div class="vx-row mt-2">
            <div class="vx-col w-1/2"><label class="vs-input--label">Marca</label></div>     
            <div class="vx-col w-1/2"><label class="vs-input--label">Tipo</label></div>     
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/2">
                <v-select                                               
                    :options="CONSTANTE_MARCA_MODELOS_MODULOS" 
                    v-model="data_local.marca"    
                    :clearable="false"
                    :reduce="r => r.data"   
                    :disabled="registroOriginal.hasOwnProperty('codigo')"   
                    @input="alterarModelo"              
                    class="w-full vs-input--label">
                </v-select> 
            </div>     
            <div class="vx-col w-1/2">
                    <v-select                                               
                        :options="getModeloModulo" 
                        v-model="data_local.tipo"    
                        :clearable="false"
                        :reduce="r => r.data"                    
                        class="w-full vs-input--label">
                    </v-select> 
            </div>     
        </div>     
        <div class="vx-row mt-2">
            <div class="vx-col w-1/3"><label class="vs-input--label">Código</label></div>     
            <div class="vx-col w-1/3"><label class="vs-input--label">Serial</label></div>     
            <div class="vx-col w-1/3"><label class="vs-input--label">Status</label></div>     
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/3">
                 <vs-input class="w-full" v-model="data_local.codigo" :maxlength="60" :disabled="registroOriginal.hasOwnProperty('codigo')"/> 
            </div>     
            <div class="vx-col w-1/3">
                <vs-input class="w-full" v-model="data_local.serial" :maxlength="60"/> 
            </div>     
            <div class="vx-col w-1/3">
                 <v-select                                               
                    :options="CONSTANTE_STATE_MODULO" 
                    :reduce="r => r.data"
                    :clearable="false"
                    :disabled="(registroOriginal.hasOwnProperty('codigo') && registroOriginal.stateModulo === 'ATIVO') || !registroOriginal.hasOwnProperty('codigo') "
                    v-model="data_local.stateModulo"                      
                    class="w-full vs-input--label">
                </v-select>  
            </div>     
        </div>       

        <div class="vx-row mt-2">
            <div class="vx-col w-1/2"><label class="vs-input--label mr-2">Número GSM</label></div>     
            <div class="vx-col w-1/2" v-if="data_local.marca === 'MaxtrackMTC'"><label class="vs-input--label mr-2">Número 2 GSM</label></div>     
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/2 flex">
                <vs-input class="w-full" v-model="data_local.numeroGSM" :disabled="semSimcard"/> 
                <v-select                                               
                    :options="CONSTANTE_OPERADORAS_GSM" 
                    :reduce="r => r.data"                    
                    v-model="data_local.operadoraGSM"  
                    :disabled="semSimcard"                    
                    class="w-full vs-input--label">
                </v-select>  
                <vs-checkbox v-model="semSimcard" class="w-full" @input="limparNumeroGSM"><small>Sem simcard</small></vs-checkbox>                            
            </div>     
            <div class="vx-col w-1/2 flex" v-if="data_local.marca === 'MaxtrackMTC'">
                <vs-input class="w-full" v-model="data_local.numero2GSM" :disabled="semSimcard2" /> 
                 <v-select                                               
                    :options="CONSTANTE_OPERADORAS_GSM" 
                    :reduce="r => r.data"                    
                    v-model="data_local.operadora2GSM"   
                    :disabled="semSimcard2"                   
                    class="w-full vs-input--label mt-1">
                </v-select>  
                <vs-checkbox v-model="semSimcard2" class="w-full" @input="limparNumero2GSM"><small>Sem simcard</small></vs-checkbox>                            
            </div>     
        </div> 

        <div class="vx-row mt-2">
            <div class="vx-col w-1/2"><label class="vs-input--label mr-2">Cliente Proprietário</label></div>     
            <div class="vx-col w-1/2"><label class="vs-input--label mr-2">Cliente com Posse</label></div>     
            
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/2">
                 <v-select  
                    label="nome"                                             
                    :reduce="r => r.id"
                    :options="listaClientes" 
                    v-model="data_local.idClienteProprietario"                      
                    class="w-full vs-input--label">
                </v-select>  
            </div>     
            <div class="vx-col w-1/2">
                 <v-select
                    label="nome"  
                    :reduce="r => r.id"                                           
                    :options="listaClientes" 
                    v-model="data_local.idClienteComPosse"                      
                    class="w-full vs-input--label">
                </v-select> 
            </div>             
        </div>   

        <div class="vx-row mt-2">
            <div class="vx-col w-1/3" v-if="data_local.stateModulo === 'COM TECNICO'"><label class="vs-input--label mr-2">Técnico com Posse</label></div>     
            <div class="vx-col w-2/3"><label class="vs-input--label mr-2">Observação</label></div>     
        </div>    
        <div class="vx-row">    
            <div class="vx-col w-1/3" v-if="data_local.stateModulo === 'COM TECNICO'">
                 <v-select                                               
                    label="nome"         
                    :reduce="r => r.id"                                    
                    :options="listaTecnicos" 
                    v-model="data_local.idTecnico"                      
                    class="w-full vs-input--label">
                </v-select> 
            </div>    
            <div class="vx-col w-2/3">
                <vs-textarea v-model="data_local.observacao" />
            </div>
        </div>
        <div class="vx-row mt-2" v-if="data_local.ultimaComunicacao">
            <div class="vx-col w-1/3"><label class="vs-input--label mr-2">Comunicação: </label><strong>{{data_local.ultimaComunicacao}}</strong></div>     
            <div class="vx-col w-1/3"><label class="vs-input--label mr-2">Servidor Conectado: </label><strong>{{data_local.ultimoServidorConectado}}</strong></div>     
        </div>
        <div class="vx-row mt-2" v-if="data_local.hasOwnProperty('versaoFirmware') && data_local.versaoFirmware">
            <div class="vx-col w-2/3"><label class="vs-input--label mr-2">Versão Firmaware: {{data_local.versaoFirmware.toUpperCase()}}</label></div>     
        </div>
        
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import ListarRastreamentos from '@/views/components/ListarRastreamentos'

import { CONSTANTE_STATE_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_MARCA_MODELOS_MODULOS } from '@/constantesComboBox.js'
import { CONSTANTE_OPERADORAS_GSM } from '@/constantesComboBox.js'

const URL_SERVLET = "/ModulosRest";
const NAME_ROUTE_SEARCH = "cadastro-modulos-pesquisar";

export default {   
  components: {
    flatPickr,
    ListarRastreamentos
  } ,
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return {marca: null, serial: null, tipo: null, numeroGSM: null, operadoraGSM: null, numero2GSM: null, operadora2GSM: null, stateModulo: 'EM ESTOQUE', idClienteProprietario: null,
            idTecnico: null, idClienteComPosse: null, observacao: null}
       }
    },
  },
  data() {
    return {            
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),
        listaClientes : [],
        listaTecnicos: [],
        semSimcard: true,
        semSimcard2: true,
        popUpHistoricoEventos: {
            exibir: false,            
            dataHora: null,
            dadosDaGridHistorico: []
        },
        configDatePicker: {          
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "U", 
            altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
            locale: PortugueseLocale
        },
        popUpListarRastreamentos : {
            exibir: false,
            veiculo: null,
        }, 
    }
  },  
  created(){
      this.CONSTANTE_MARCA_MODELOS_MODULOS = CONSTANTE_MARCA_MODELOS_MODULOS;
      this.CONSTANTE_STATE_MODULO = CONSTANTE_STATE_MODULO;
      this.CONSTANTE_OPERADORAS_GSM = CONSTANTE_OPERADORAS_GSM;
  }, 
  mounted() {
      this.fetchListaClientes();
      this.fetchListaTecnicos();
      
      if (this.registroOriginal.hasOwnProperty('codigo')) {
          if (this.registroOriginal.numeroGSM) {
              this.semSimcard = false;
          }
          if (this.registroOriginal.numero2GSM) {
              this.semSimcard2 = false;
          }
      } else {
          /* eslint-disable no-console */ console.log("insert");        
      }
  },
  computed: {        
    isDeletable() {
       let perfilLogado = this.$store.state.AppActiveUser.perfil;
       if (perfilLogado === "ATIVADOR" || perfilLogado === "CONFIGURADOR" || perfilLogado === "SUPERVISOR" 
            || perfilLogado === "FINANCEIRO" || perfilLogado === "MONITOR_PANICO") {
            return false;	
       } 

       return this.data_local != null && this.data_local.hasOwnProperty("codigo");
    },
    isAllowedToSave() {
       let perfilLogado = this.$store.state.AppActiveUser.perfil;
       if (perfilLogado === "FINANCEIRO" || perfilLogado === "MONITOR_PANICO") {
            return false;	
       } 

       return true;
    },
    getModeloModulo() {
        if (this.data_local.marca) {
            let marca =  this.CONSTANTE_MARCA_MODELOS_MODULOS.find( ({data}) => data === this.data_local.marca);
            if (marca) {
                return marca.modelos;
            }
        }

        return [];
    }
  },
  methods: {  
    abrirPopUpListarRastreamentos() {
        this.popUpListarRastreamentos.exibir = true;
        this.popUpListarRastreamentos.veiculo =  {codigoModulo: this.data_local.codigo, marcaModulo: this.data_local.marca };
    }, 
    limparNumeroGSM() {
        this.data_local.numeroGSM = null;
        this.data_local.operadoraGSM = null;
    },
    limparNumero2GSM() {
        this.data_local.numero2GSM = null;
        this.data_local.numero2GSM = null;
    },
    alterarModelo() {
        this.data_local.tipo = null;
    }, 
    fetchListaClientes() {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
            
      axios.post("/ClientesRest", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.listaClientes = response.data;         
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    }, 
    fetchListaTecnicos() {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
      
      axios.post("/TecnicoRest", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.listaTecnicos = response.data;         
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    }, 
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'Delete');      
        params.append('codigo', this.data_local.codigo);   
        params.append('marca', this.data_local.marca);      
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            params.append('idCliente', this.$store.state.AppActiveUser.id);
            
            if (this.registroOriginal.hasOwnProperty("codigo")) {
                params.append('method', 'Update');      
            }  else {
                params.append('method', 'Insert');      
            }  

            params.append('codigo', this.data_local.codigo);   
            params.append('marca', this.data_local.marca);      
            params.append('serial', this.data_local.serial);      
            params.append('tipo', this.data_local.tipo);
            params.append('stateModulo', this.data_local.stateModulo);     
            
            if (this.data_local.operadoraGSM && this.data_local.operadoraGSM === "SAT") {
                this.data_local.numeroGSM = this.data_local.codigo;
            }

            if (this.data_local.operadora2GSM && this.data_local.operadora2GSM === "SAT") {
                this.data_local.numero2GSM = this.data_local.codigo;
            }
            
            if (this.data_local.numeroGSM) {  params.append('numeroGSM', this.data_local.numeroGSM); }
            if (this.data_local.operadoraGSM) {  params.append('operadoraGSM', this.data_local.operadoraGSM); }
            if (this.data_local.numero2GSM) {  params.append('numero2GSM', this.data_local.numero2GSM); }
            if (this.data_local.operadora2GSM) {  params.append('operadora2GSM', this.data_local.operadora2GSM); }
            if (this.data_local.idClienteProprietario) {  params.append('idClienteProprietario', this.data_local.idClienteProprietario); }
            if (this.data_local.idTecnico) {  params.append('idTecnico', this.data_local.idTecnico); }    
            if (this.data_local.observacao) {  params.append('observacao', this.data_local.observacao); }
            if (this.data_local.idClienteComPosse) {  params.append('idClienteComPosse', this.data_local.idClienteComPosse); }
                    
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    /* eslint-disable no-console */ console.log(response.data);
                    if (response.data.hasOwnProperty("error")) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                    } else {
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];

        if (!this.data_local.codigo) {
            this.errorsValidacao.push("Código obrigatório");
        }

        if (!this.data_local.marca) {
            this.errorsValidacao.push("Marca obrigatório");
        }

        if (!this.data_local.tipo) {
            this.errorsValidacao.push("Tipo obrigatório");
        }

        if (!this.data_local.serial) {
            this.errorsValidacao.push("Serial obrigatório");
        }

        if (this.data_local.marca === "Maxtrack" && this.data_local.codigo && this.data_local.codigo.length != 7 && this.data_local.codigo.length != 10) {
            this.errorsValidacao.push("Para o módulo Maxtrack o código do módulo deverá ter 7 ou 10 caracteres.");		
        }
        
        if (this.data_local.marca === "MaxtrackMTC" && this.data_local.codigo && this.data_local.codigo.length != 7 ) {
            this.errorsValidacao.push("Para o módulo Maxtrack MTC o código do módulo deverá ter 7.");		
        }
        
        if (this.data_local.marca === "MaxtrackMTC" && !this.semSimcard2 ) {
            if (this.data_local.operadora2GSM && this.data_local.operadora2GSM !== "SAT" && !this.data_local.numero2GSM) {
                this.errorsValidacao.push("Informe o numero do simcard 2 instalado.");
            }
            
            if (this.data_local.operadora2GSM && this.data_local.operadora2GSM !== "SAT" && this.data_local.numero2GSM && this.data_local.numero2GSM.length < 10) {
                this.errorsValidacao.push("Informe o numero do simcard 2 com o DDD.");
            }
            
            if (!this.data_local.operadora2GSM) {
                this.errorsValidacao.push("Informe a operadora do simcard 2 instalado.");
            }
            
        } else {
            this.data_local.numero2GSM = null;
            this.data_local.operadora2GSM = null;
        }

        /* eslint-disable no-console */ console.log('OperadoraGSM=' + this.data_local.operadoraGSM);

        if (!this.semSimcard) {
            if (this.data_local.operadoraGSM && this.data_local.operadoraGSM !== "SAT" && !this.data_local.numeroGSM) {
                this.errorsValidacao.push("Informe o numero do simcard instalado.");
            }
            
            if (this.data_local.operadoraGSM && this.data_local.operadoraGSM !== "SAT" && this.data_local.numeroGSM && this.data_local.numeroGSM.length < 10) {
                this.errorsValidacao.push("Informe o numero do simcard com o DDD.");
            }
            
            if (!this.data_local.operadoraGSM) {
                this.errorsValidacao.push("Informe a operadora do simcard instalado.");
            }

        } else {
            this.data_local.numeroGSM = null;
            this.data_local.operadoraGSM = null;
        }

        if (this.data_local.stateModulo === "DE TERCEIRO" && !this.data_local.idClienteProprietario) {
            this.errorsValidacao.push("Selecione o cliente proprietário do módulo.");
        }
        
        if (this.data_local.stateModulo === "ATIVO" && this.data_local.stateModulo !== "ATIVO") {
            this.errorsValidacao.push("O status ativo só pode ser alterado através da Ativação do Módulo em um veículo.");
        }

        if (this.data_local.stateModulo === "COM CLIENTE" && !this.data_local.idClienteComPosse) {
            this.errorsValidacao.push("Selecione o cliente que está com a posse do módulo.");
        }
        
        if (this.data_local.stateModulo === "COM TECNICO" && !this.data_local.idTecnico) {
            this.errorsValidacao.push("Selecione o técnico.");
        }

        let perfilLogado = this.$store.state.AppActiveUser.perfil;
            if (perfilLogado === "ATIVADOR" || perfilLogado === "CONFIGURADOR" || perfilLogado === "SUPERVISOR" 
            || perfilLogado === "FINANCEIRO" || perfilLogado === "MONITOR_PANICO") {  
                let status = this.data_local.stateModulo;      
                if (status === "PERDIDO" || status === "ROUBADO" || status === "QUEIMADO" || 
                    status === "QUEBRADO" || status === "INATIVO") {
                    this.errorsValidacao.push("Você não tem permissão para alterar o status de um módulo ");		
                }
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        
      return false;
      
    },   
    fetchHistoricoErros() {     
        this.$vs.loading() 
        const params = new URLSearchParams();
        params.append('outputFormat', 'JSON');
        params.append('codigoModulo', this.data_local.codigo);
        if (this.popUpHistoricoEventos.dataHora) {
            params.append('dataHora', this.popUpHistoricoEventos.dataHora*1000);
        }

        axios.post("/ListarLogErroModulo", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.popUpHistoricoEventos.dadosDaGridHistorico = response.data;         
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    },   
    abrirPopUpHistoricoErros() {
        this.popUpHistoricoEventos.exibir = true;
        this.popUpHistoricoEventos.dataHora = String(new Date().getTime() / 1000);
        this.fetchHistoricoErros();
    },   
  }
}
</script>